import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer
} from 'recharts';

class SimpleAreaChart extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      uv: PropTypes.number,
      pv: PropTypes.number,
    })).isRequired,
  }

  render() {
    return (
      <ResponsiveContainer height={300} width='100%'>
        <AreaChart
          width={730}
          height={250}
          data={this.props.data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#6b45fe" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#6b45fe" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="name"
            label={{ value: "Count", angle: 0, position: "insideBottom" }}
          />
          <YAxis label={{ value: "Count", angle: -90, position: "insideLeft" }} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="uv"
            stroke="#6b45fe"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default SimpleAreaChart;
