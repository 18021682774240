import {
  FETCH_WS_FEED,
  FETCH_CAMERA_SUCCESS,
  FETCH_ROI_SUCCESS,
  DELETE_ROI_SUCCESS,
  FETCH_ALGO_SUCCESS,
  UPDATE_CAMERA_SUCCESS
} from '../actions/feed';

export default function feed(
  state = {
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_WS_FEED:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        feed: action.feed
      });
    case FETCH_CAMERA_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        cameras: action.cameras
      });
    case FETCH_ROI_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        roi: action.roi
      });
    case DELETE_ROI_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        deleteRoi: action.deleteRoi
      });
    case FETCH_ALGO_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        algorithms: action.algorithms
      });
    case UPDATE_CAMERA_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        camera_id: action.camera_id
      });
    default:
      return state;
  }
}
