import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  InputGroup,
  Input
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import { getTicketLog, getTicketDetails, changeTicket, getTicketBreach } from "../../actions/ticket";
import s from "./TicketDetails.module.scss";
import { format } from "date-fns";

class TicketDetail extends Component {

  constructor(props) {
    super(props);

  }
  /* eslint-disable */
  static propTypes = {
    logs: PropTypes.any,
    ticket: PropTypes.any,
    breaches: PropTypes.any,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    logs: [],
    ticket: undefined,
    breaches: [],
    isFetching: false,
  };

  componentDidMount() {
    console.log(this.props.match.params.id)
    this.props.dispatch(getTicketLog(this.props.match.params.id));
    this.props.dispatch(getTicketDetails(this.props.match.params.id));
    this.props.dispatch(getTicketBreach(this.props.match.params.id));
  }
  comment = () => {
    console.log('comment')
  }
  closeTicket = (ticket_id) => {
    new Promise((resolve, reject) => {
      this.props.dispatch(changeTicket(ticket_id, 'closed'));
      resolve()
    }).then(res => {
      this.props.dispatch(getTicketLog(ticket_id));
    })
  }
  archiveTicket = (ticket_id) => {
    new Promise((resolve, reject) => {
      this.props.dispatch(changeTicket(ticket_id, 'archived'));
      resolve()
    }).then(res => {
      this.props.dispatch(getTicketLog(ticket_id));
    })
  }
  render() {
    console.log(this.props.breaches)

    return (

      <div className={s.root}>
        {this.props.isFetching && (
          <>
            <Breadcrumb>
              <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
              <BreadcrumbItem active>Ticket Details</BreadcrumbItem>
            </Breadcrumb>
            <Row>
              <Col sm={6} md={8}>
                <h1 className="mb-lg">Ticket Details</h1>
              </Col>
              <Col sm={6} md={4}>
                <Button className="btn btn-success" onClick={() => this.closeTicket(this.props.match.params.id)}>Close Ticket</Button>
                <span style={{ marginLeft: 15, cursor: 'pointer' }} onClick={() => this.archiveTicket(this.props.match.params.id)}>
                  <i className="fa fa-trash" style={{ fontSize: 15 }} />
                  &nbsp;
                  Archive
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm={4} md={4}>
                <Widget title="Hard Hat Breach Detected">
                  <div className={s.underLine}></div>
                  <div>Ticket ID: {this.props.ticket && this.props.ticket._id}</div>
                  <div>Camera: {this.props.ticket && this.props.ticket.camera_name}</div>
                  <div>Location: Location</div>
                  <div>Breach Time: {this.props.ticket && format(new Date(this.props.ticket.updatedAt), "do LLL hh:mm a")}</div>
                </Widget>
              </Col>
              <Col sm={4} md={4}>
                <Widget title="Ticket Details">
                  <div className={s.underLine}></div>
                  <div>Status: {this.props.ticket && this.props.ticket.status}</div>
                  <div>Assigned To: {this.props.ticket && 'Admin'}</div>
                  <div>Created At: {this.props.ticket && format(new Date(this.props.ticket.createdAt), "do LLL hh:mm a")}</div>
                </Widget>
              </Col>
              <Col sm={4} md={4}>
                <Widget title="Log Details">
                  <div className={s.underLine}></div>
                  <ul className={s.list}>
                    {this.props.logs &&
                      this.props.logs.map((log) => (
                        <li className={s.listItem} key={log._id} value={log._id}>
                          {log.log_details}
                          <span className={s.listDate}>{format(new Date(log.updatedAt), "do LLL hh:mm a")}</span>
                        </li>
                      ))}
                  </ul>
                  <div>
                    <InputGroup>
                      <Input placeholder="Add a Comments" />
                      <i className="fa fa-paper-plane" onClick={() => this.comment()} style={{ color: '#675cff', fontSize: 18, position: 'relative', top: 17, right: 40, cursor: 'pointer', zIndex: 5 }} />
                    </InputGroup>
                  </div>
                </Widget>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <Widget title="Media">
                  {this.props.breaches.length > 0 && (
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                      <img src={this.props.breaches[0].link} width={500} />
                      <img src={this.props.breaches[1].link} width={500} />
                    </div>
                  )}
                </Widget>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.ticket.isFetching,
    logs: state.ticket.logs,
    ticket: state.ticket.ticket,
    breaches: state.ticket.breaches,
  };
}

export default connect(mapStateToProps)(TicketDetail);
