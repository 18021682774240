import {
  FETCH_CONTA_REQUEST,
  FETCH_CONTA_SUCCESS,
  FETCH_CONTA_FAILURE,
  FETCH_CONTA_SAFETY_REQUEST,
  FETCH_CONTA_SAFETY_SUCCESS,
  FETCH_CONTA_SAFETY_FAILURE,
  FETCH_CONTA_7DAY_REQUEST,
  FETCH_CONTA_7DAY_SUCCESS,
  FETCH_CONTA_7DAY_FAILURE,
  FETCH_CONTA_TREND_REQUEST,
  FETCH_CONTA_TREND_SUCCESS,
  FETCH_CONTA_TREND_FAILURE
} from '../actions/contatrack';

export default function contatrack(
  state = {
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_CONTA_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_CONTA_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        conta: action.conta,
      });
    case FETCH_CONTA_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    case FETCH_CONTA_SAFETY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_CONTA_SAFETY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        safety: action.safety,
      });
    case FETCH_CONTA_SAFETY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    case FETCH_CONTA_7DAY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_CONTA_7DAY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        report: action.report,
      });
    case FETCH_CONTA_7DAY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    case FETCH_CONTA_TREND_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_CONTA_TREND_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        trends: action.report,
      });
    case FETCH_CONTA_TREND_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    default:
      return state;
  }
}
