import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { fetch7DayHat } from "../../../actions/unsafe";
import { format, addDays, subDays } from 'date-fns';
class HardhatChartSingle extends PureComponent {
  static defaultProps = {
    report: [],
    isFetching: false,
  };

  componentDidMount(){
    const data = {
      "from_date": format(subDays(new Date(), 6), 'yyyy-MM-dd'),
      "to_date": format(addDays(new Date(), 1), 'yyyy-MM-dd')
    }
    this.props.dispatch(fetch7DayHat());
  }

	render () {
  	return (
      <ResponsiveContainer height={300} width="100%">
        <BarChart
          width={600}
          height={300}
          data={this.props.report}
          margin={{ top: 20, left: -10, width: 20 }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="to_day" />
          <YAxis yAxisId="left" orientation="left" stroke="#4285f4" />
          <Tooltip cursor={{ fill: "transparent" }} />
          <Legend />
          <Bar yAxisId="left" barSize={20} dataKey="total" fill="#4285f4" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.unsafe.isFetching,
    report: state.unsafe.report,
  };
}

export default connect(mapStateToProps)(HardhatChartSingle);