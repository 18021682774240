import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { fetchContaSafety } from "../../../actions/contatrack";
import { format, addDays } from 'date-fns';

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;
const data = [
  { name: 'Total', value: 100 }
];
class SinglePieChart extends PureComponent {
  static defaultProps = {
    safety: {},
    isFetching: false,
  };

  componentDidMount() {
    const data = {
      "from_date": format(new Date(), 'yyyy-MM-dd'),
      "to_date": format(addDays(new Date(), 1), 'yyyy-MM-dd')
    }
    this.props.dispatch(fetchContaSafety(data));
  }
  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }

  render() {
    let getColor = 'donut deepgreen';
    let avg = this.props.safety.score;
    if (this.props.safety.color === 'Green') {
        getColor = 'donut deepgreen';
    }
    else if (this.props.safety.color === 'Yellow') {
        getColor = 'donut orange';
    }
    else if (this.props.safety.color === 'Red') {
        getColor = 'donut red';
    }
    return (
      <ResponsiveContainer height={300} width='100%'>
        {/* <PieChart  onMouseEnter={this.onPieEnter} height={297} width={420}>
          <Pie
            data={data}
            innerRadius={80}
            outerRadius={130}
            dataKey="value"
            nameKey="name"
            fill="gray"
          >
            <div>0.6</div>
            {data.map((entry, index) => (
              <Cell key={index} fill={this.props.safety.color} />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            align="right"
            layout="horizontal"
            align="middle"
            iconSize={12}
            iconType="circle"
            wrapperStyle={{ fontSize: "14px", paddingLeft: "10px" }}
          />
        </PieChart> */}
        <div className="rounddonut">

          <div className={getColor}>
            <div className="getColorText">
              <h2>
                {avg}
              </h2>
              <small> total number of breach / total number of employee / number of days</small>
            </div>
          </div>

          <div className="legendprt">
            <div className="legentpanel">
              <i className="boxred"></i>
              Immediate Attention Required
          </div>
            <div className="legentpanel">
              <i className="boxorange"></i>
              Breach Minimisation Required
          </div>
            <div className="legentpanel">
              <i className="boxgreen"></i>
              Optimum Safety Level
          </div>
          </div>
        </div>
      </ResponsiveContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.contatrack.isFetching,
    safety: state.contatrack.safety,
  };
}

export default connect(mapStateToProps)(SinglePieChart);
