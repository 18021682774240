import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Progress,
} from "reactstrap";
import $ from "jquery";

import Widget from "../../components/Widget/Widget";
import feed from "../../images/feed.jpg";

import {
  fetchFeed,
  fetchCameras,
  fetchRoi,
  deleteRoi,
  fetchAlgorithms,
} from "../../actions/feed";
import "./app.min.css";
import "./app.min.js";

class Roi extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.imgElement = React.createRef();
    this.props.dispatch(fetchFeed(""));
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    feed: PropTypes.string, // eslint-disable-line
    isFetching: PropTypes.bool,
    cameras: PropTypes.any,
    roi: PropTypes.any,
    algorithms: PropTypes.any
  };

  static defaultProps = {
    isFetching: false,
    feed: "",
    cameras: [
      {_id: ""}
    ],
    algorithms: [],
    roi: {
      data: {
        titles: [],
        targets: [],
        svg: "",
      },
    },
    deleteRoi: {}
  };

  state = {
    error: "",
    count: 0,
    overlay: false
  }

  componentDidMount() {
    (function ($) {
      $(document).trigger("init");
    })(jQuery);
    this.props.dispatch(fetchCameras());
    this.props.dispatch(fetchAlgorithms());
  }

  onChange = (value) => {
    this.props.dispatch(fetchFeed(value));
    this.props.dispatch(fetchRoi(value));
    if (value == "") {
      $("#image-mapper-table").css({ display: "none" });
    } else {
      $("#image-mapper-table").css({ display: "block" });
    }
  };

  onClear = (event) => {
    console.log(event);
    this.props.dispatch(deleteRoi($("#camera").val()));
    setTimeout(function () { location.reload(); }, 3000);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.algorithms !== this.props.algorithms) {
      $(".input-target").html(`<option value="">---</option>`);
      this.props.algorithms.map((algorithm) => {
        $(".input-target").append(
          `<option key="${algorithm._id}" value="${algorithm._id}">${algorithm.name}</option>`
        );
      });
    }
  }

  getModuleById(id) {
    if (id) {
      const algos = this.props.algorithms;
      console.log(algos);
      const findAlgo = algos.find((algo) => algo._id === id);
      console.log(findAlgo.name);
      return findAlgo.name;
    }
  }
  onSave() {

    //let error = false;
    const $targetsInputs = $(`select[name^="im["][name$="][target]"]`);
    const $titleInputs = $(`input[name^="im["][name$="][title]"]`);
    const $pointsInputs = $(`input[name^="im["][name$="][active]"]`);

    if ($targetsInputs.val() != '' && $titleInputs.val() != '' && $pointsInputs.val() != '') {
      this.setState({ overlay: true, error: "" })
      setInterval(() => {
        this.setState({ count: this.state.count + 1 })
        if (this.state.count == 100) {
          location.reload();
        }
      }, 1000 * 5);

    } else {
      this.setState({error: "Please draw a ROI and enter required fields"})
    }
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>ROI</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="page-title mb-lg">Region of Interest (ROI)</h1>
        <Row>
          <Col xs={8} md={8}>
            <Widget title="Camera" close collapse>
              <div
                id="image-map"
                className="imagemaps-wrapper"
                style={{ border: "1px solid", marginTop: 10, width: "100%" }}
              >
                <img
                  id="image"
                  src={this.props.feed}
                  className="image-mapper-img img-fluid"
                  draggable="false"
                />
                {/* <svg class="image-mapper-svg" dangerouslySetInnerHTML={{__html: (this.props.roi.success?this.props.roi.data.svg:"")}}>
                </svg> */}
                {this.props.isFetching && <span>Loading...</span>}
              </div>
            </Widget>
          </Col>
          <Col xs={4} md={4} className="camera-select-card">
            <Form>
              <FormGroup>
                <Input
                  type="select"
                  name="camera"
                  id="camera"
                  style={{ marginTop: "7%", marginLeft: 3 }}
                  onChange={(e) => this.onChange(`${e.target.value}`)}
                >
                  <option value="">Select Camera</option>
                  {this.props.cameras &&
                    this.props.cameras.map((camera) => (
                      <option key={camera._id} value={camera._id}>
                        {camera.id}
                      </option>
                    ))}
                </Input>
                <Input type="hidden" id="docker_id" />
                <Input type="hidden" id="ip" />
              </FormGroup>
            </Form>
            <fieldset>
              <table
                className="table"
                id="image-mapper-table"
                style={{ display: "none" }}
              >
                <tbody>
                  <tr
                    className="input-tr"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <td colSpan={5}>
                      <div className="control-label input-sm">
                        <input
                          type="radio"
                          className="active"
                          name="im[0][active]"
                          defaultChecked={true}
                        />
                        <div style={{ marginLeft: 15, width: "100%" }}>
                          <label>Select Shape</label>
                          <select
                            className="shape"
                            name="im[0][shape]"
                            className="form-control input-sm"
                          >
                            <option value="rect">Rect</option>
                            <option value="poly">Poly</option>
                          </select>
                        </div>
                        <button
                          className="btn btn-sm remove-row"
                          name="im[0][remove]"
                        >
                          <span className="glyphicon glyphicon-remove"></span>
                        </button>
                      </div>
                      <div className="control-label input-sm">
                        <div>
                          <label>Title</label>
                          <input
                            type="text"
                            className="title"
                            name="im[0][title]"
                            placeholder="Title"
                            className="form-control input-sm input-title"
                          />
                        </div>
                        <div>
                          <label>Target</label>
                          <select
                            className="target"
                            name="im[0][target]"
                            className="form-control input-sm input-target"
                          >
                            <option value="">
                              ---
                            </option>
                            <option value="602f5d1f26fc0a25960c927a">
                              Attendance Monitoring
                            </option>
                            <option value="604b48e88a4e3a30aa3eb060">
                              Speed Detection
                            </option>
                            <option value="606ed9352e39025e1a9f4cfb">
                              Intruder Detection
                            </option>
                            <option value="608fc077a23f94101c5d851f">
                              Occupancy Monitor
                            </option>
                            <option value="60a4d0aadda01944988dc4f3">
                              Hard-Hat Monitor
                            </option>
                            <option value="60b0b04fcea3a667ac7c4235">
                              Face-Mask Monitor
                            </option>
                            <option value="60cb154db5ea6632c8583ba4">
                              Number Plate Detection
                            </option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={3} className="form-control-error text-error">{this.state.error}</th>
                  </tr>
                  <tr>
                    <th colSpan={3}>
                      {/* <button
                        type="button"
                        className="btn btn-danger btn-sm add-row"
                        style={{ width: "100%", margin: 0 }}
                      >
                        <span className="glyphicon glyphicon-plus"></span>
                        Add New ROI
                      </button> */}
                    </th>
                    <th colSpan={2}>
                      <button
                        type="button"
                        className="btn btn-success btn-sm pull-right btn-code"
                        data-target="#modal-code"
                        onClick={() => this.onSave()}
                      >
                        Save
                      </button>
                    </th>
                  </tr>
                </tfoot>
              </table>
              <div className="clear-roi" style={{ display: "none" }}>
                <div>
                  Region of interest is already selected, please find the
                  details below
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <td>ROI Title</td>
                      <td>Use Case</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.roi.data &&
                      this.props.roi.data.titles.map((title, index) => (
                        <tr key={index}>
                          <td>{title}</td>
                          <td>
                            {this.getModuleById(
                              this.props.roi.data.targets[index]
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <button
                  type="button"
                  className="btn btn-danger btn-sm btn-clear"
                  data-target="#modal-code"
                  onClick={(e) => this.onClear(`${e}`)}
                >
                  Clear ROI
                </button>
              </div>
            </fieldset>
            <textarea
              className="form-control input-sm"
              id="modal-code-result"
              style={{ display: "none" }}
            ></textarea>
            <span style={{ fontSize: 10 }}>
              ***Any ROI change would reflect after an approximate 10 minutes
              from creation of a new ROI
            </span>
          </Col>
        </Row>
        {this.state.overlay && (
          <>
            <div>
              <Progress bar animated color="danger" value={this.state.count}>{this.state.count}%</Progress>
            </div>
            <div className="overclay"></div>
            <div>Please do not Refresh or Reload your page. We are enriching your vision!</div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.feed.isFetching,
    feed: state.feed.feed,
    cameras: state.feed.cameras,
    roi: state.feed.roi,
    deleteRoi: state.feed.roi,
    algorithms: state.feed.algorithms,
  };
}

export default connect(mapStateToProps)(Roi);
