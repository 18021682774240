import {
  FETCH_UNSAFE_REQUEST,
  FETCH_UNSAFE_SUCCESS,
  FETCH_UNSAFE_FAILURE,
  FETCH_UNSAFE_7DAY_REQUEST,
  FETCH_UNSAFE_7DAY_SUCCESS,
  FETCH_UNSAFE_7DAY_FAILURE,
  FETCH_PEOPLE_REQUEST,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE,
  FETCH_HAT_REQUEST,
  FETCH_HAT_SUCCESS,
  FETCH_HAT_FAILURE,
  FETCH_MASK_REQUEST,
  FETCH_MASK_SUCCESS,
  FETCH_MASK_FAILURE,
} from '../actions/unsafe';

export default function unsafe(
  state = {
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_UNSAFE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_UNSAFE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        unsafe: action.unsafe,
      });
    case FETCH_UNSAFE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    case FETCH_UNSAFE_7DAY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_UNSAFE_7DAY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        report: action.report,
      });
    case FETCH_UNSAFE_7DAY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    case FETCH_PEOPLE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_PEOPLE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        people: action.people,
      });
    case FETCH_PEOPLE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    case FETCH_HAT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_HAT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        hardhat: action.hardhat,
      });
    case FETCH_HAT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    case FETCH_MASK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_MASK_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        facemask: action.facemask,
      });
    case FETCH_MASK_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    default:
      return state;
  }
}
