import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Widget from '../../../components/Widget';
import { Input } from 'reactstrap';
import { fetch7DayReport } from "../../../actions/unsafe";
import { fetchCameras } from "../../../actions/feed";
import { postApi } from '../../../util/Service';
import { parseISO } from 'date-fns';

class UnsafeBreach extends PureComponent {
    static defaultProps = {
        cameras: [],
        isFetching: false,
    };
    constructor() {
        super();
        this.state = {
            data: [],
            isLoading: false
        }
    }
    componentDidMount() {
        this.props.dispatch(fetchCameras());
    }

    onChange(camera_id) {
        postApi('/api/unsafe/by-camera', { camera_id })
            .then(res => {
                console.log(res)
                this.setState({
                    data: res.data.data,
                    isLoading: false
                })
            })
            .catch(err => {
                console.log("Fetch Data failed");
            })
    }
    render() {
        return (
            <Widget>
                <Input type="select" name="camera" id="camera" style={{ marginTop: "7%", marginLeft: 3 }}
                    onChange={(e) => this.onChange(`${e.target.value}`)}>
                    <option value="">Select Camera</option>
                    {this.props.cameras &&
                        this.props.cameras.map(camera => (
                            <option key={camera._id} value={camera._id}>{camera.id}</option>
                        ))}
                </Input>
                {this.state.data.length > 0 && (
                    <>
                        <h1 style={{ textAlign: 'center' }}>{this.state.data[0].count}</h1>
                        <h4 style={{ textAlign: 'center' }}>Breaches</h4>
                        <h6 style={{ marginLeft: 30, marginRight: 30 }}>
                            {parseISO(this.state.data[0].last_date).toLocaleString('default', { dateStyle: 'medium' })}
                            <span style={{ float: 'right' }}>
                                {parseISO(this.state.data[0].last_date).toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true })}
                            </span>
                        </h6>
                    </>
                )}

            </Widget>
        );
    }
}

function mapStateToProps(state) {
    return {
        isFetching: state.unsafe.isFetching,
        cameras: state.feed.cameras,
    };
}

export default connect(mapStateToProps)(UnsafeBreach);