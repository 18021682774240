import {
  FETCH_ATTENDANCE_REQUEST,
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_ATTENDANCE_FAILURE,
} from '../actions/attendance';

export default function attendance(
  state = {
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_ATTENDANCE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_ATTENDANCE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        attendance: action.attendance,
      });
    case FETCH_ATTENDANCE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    default:
      return state;
  }
}
