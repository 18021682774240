import React from "react";

const ThemeSwitcher = ({ click, activeTheme }) => (
  <label>
    <span className="toggle-checkbox-label">Switch Mode</span>
    <input
      className="toggle-checkbox"
      type="checkbox"
      checked={activeTheme === "light"}
      onChange={() => click()}
    />
    <div className="toggle-slot">
      <div className="sun-icon-wrapper">
        {/* <div
          class="iconify sun-icon"
          data-icon="feather-sun"
          data-inline="false"
        ></div> */}
        <i className="fa fa-sun sun-icon"></i>
      </div>
      <div className="toggle-button"></div>
      <div className="moon-icon-wrapper">
        <i className="fa fa-moon moon-icon"></i>

        {/* <div
          class="iconify moon-icon"
          data-icon="feather-moon"
          data-inline="false"
        ></div> */}
      </div>
    </div>
  </label>
);

export default ThemeSwitcher;
