import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import { fetchCameras } from "../../actions/feed";
import s from "./Liveview.module.scss";
import left from '../../images/arrow_left.svg';
import right from '../../images/arrow_right.svg'

class Liveview extends Component {
  /* eslint-disable */
  static propTypes = {
    cameras: PropTypes.any,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    cameras: [],
    isFetching: false,
  };

  state = {
    passkey: localStorage.getItem('pass_key'),
    page: 1,
    perPage: 4
  }

  componentDidMount() {
    this.props.dispatch(fetchCameras());
  }

  previous() {
    this.setState({
      page: this.state.page - 1
    })
  }

  next() {
    this.setState({
      page: this.state.page + 1
    })
  }

  Paginator(items, page, per_page) {

    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,

      paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(items.length / per_page);
    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: (total_pages > page) ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems
    };
  }

  render() {
    let pager = this.Paginator(this.props.cameras, this.state.page, this.state.perPage)
    console.log(pager)
    return (
      <div className={s.root}>
        <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>Grid View</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="mb-lg">Grid View</h1>
        <div  className={s.control}>
          <Button color="default" onClick={() => this.previous()} disabled={pager.pre_page==null}>
            <img src={left} width={50} />
          </Button>
          <Button color="default" onClick={() => this.next()} disabled={pager.next_page==null}>
            <img src={right} width={50} />
          </Button>
        </div>
        <Row>
          <Col sm={12} md={12}>
            <Widget title="">
              <div  style={{display:'flex', flexWrap: 'wrap'}}>
                {this.props.cameras && 
                this.Paginator(this.props.cameras, this.state.page, this.state.perPage).data.map((camera) =>(
                  <div
                    id="image-map"
                    className={s.imagewrap}
                    key={camera._id}
                  >
                    <img
                      id="image"
                      src={`https://feed.hipla.io/${this.state.passkey}/${camera._id}/live`}
                      className="image-mapper-img img-fluid"
                      draggable="false"
                    />
                  </div>
                ))}

                {/* <div
                  id="image-map"
                  className={s.imagewrap}
                >
                  <img
                    id="image"
                    src="http://65.1.224.91:5000/SI31793/602f5d548aba9e57e045973e/live"
                    className="image-mapper-img img-fluid"
                    draggable="false"
                  />
                </div>

                <div
                  id="image-map"
                  className={s.imagewrap}
                >
                  <img
                    id="image"
                    src="http://65.1.224.91:5000/SI31793/602f5d718aba9e57e0459743/live"
                    className="image-mapper-img img-fluid"
                    draggable="false"
                  />
                </div>
                <div
                  id="image-map"
                  className={s.imagewrap}
                >
                  <img
                    id="image"
                    src="http://65.1.224.91:5000/SI31793/609badb9649ccc75e05c536f/live"
                    className="image-mapper-img img-fluid"
                    draggable="false"
                  />
                </div> */}
              </div>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.unsafe.isFetching,
    cameras: state.feed.cameras,
  };
}

export default connect(mapStateToProps)(Liveview);
