import components from './components.svg';
import dashboard from './dashboard.svg';
import notifications from './notifications.svg';
import typography from './typography.svg';
import tables from './tables.svg';
import logo from './logo.svg';
import settings from './settings.svg';
import notification from './notification.svg';
import mail from './mail.svg';
import contatracing from './contatracing.svg';
import attandancesheet from './attandancesheet.svg';
import unsafe from './unsafe.svg';
import insights from './Insights.svg';
import occupancy from './Occupancy.svg';
import Hardhat from './Hardhat.svg';
import facemask from './facemask.svg';
import licenceplate from './licenceplate.svg';
import cv from './Computer vision.svg';
import md from './Mask Detection.svg';
import ticket from './ticket.svg';

export default { 
  components,
  dashboard,
  notifications,
  typography,
  tables,
  logo,
  settings,
  notification,
  mail,
  contatracing,
  attandancesheet,
  unsafe,
  insights,
  occupancy,
  Hardhat,
  facemask,
  licenceplate,
  cv,
  md,
  ticket
}