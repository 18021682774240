import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import Widget from "../../components/Widget/Widget";
//import mqtt from 'mqtt';
import customStyles from "../../styles/customDataTableStyles";
import { fetchMask } from "../../actions/unsafe";
import s from "./Facemask.module.scss";
import { format, subDays, addDays } from "date-fns";
// import { DATE } from 'sequelize/types';
//var mqtt = require('mqtt')
class Facemask extends Component {
  /* eslint-disable */
  static propTypes = {
    facemasks: PropTypes.any,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    facemasks: [],
    isFetching: false,
  };

  state = {
    isDropdownOpened: false,
    isOpenImage: false,
    selectedImage: "",
    from_date: format(new Date(), "yyyy-MM-dd"),
    to_date: format(new Date(), "yyyy-MM-dd"),
  };

  componentDidMount() {
    const req = {
      from_date: format(new Date(), "yyyy-MM-dd"),
      to_date: format(new Date(), "yyyy-MM-dd"),
    };
    this.props.dispatch(fetchMask(req));
  }

  formatDate = (str) => {
    return str.replace(/,.*$/, "");
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpened: !prevState.isDropdownOpened,
    }));
  };
  openImage = (url) => {
    this.setState({
      isOpenImage: true,
      selectedImage: url,
    });
  };
  closeImage = () => {
    this.setState({
      isOpenImage: false,
    });
  };
  exportMaskToCSV = () => {
    let rows = [["Camera_Location", "Date & Time"]];
    this.props.facemasks.map((res, i) => {
      rows[i + 1] = new Array(
        res.camera_name,
        format(new Date(res.date_time), "do LLL hh:mm a")
      );
    });

    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `facemask.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  onChange = (date1, date2) => {
    //console.log(date1, date2)
    this.setState({
      from_date: date1,
      to_date: date2,
    });
    const req = {
      from_date: date1,
      to_date: date2,
    };
    this.props.dispatch(fetchMask(req));
  };

  render() {
    console.log(this.props.facemasks);
    const columns = [
      {
        name: "CAMERA-LOCATION",
        selector: "camera_name",
        fontSize: "25px",
        cell: (row) => <div style={{ marginLeft: 35 }}>{row.camera_name}</div>,
      },
      {
        name: "SNAPSHOT",
        selector: "link",
        cell: (row) => (
          <>
            {
              <img
                style={{ cursor: "pointer" }}
                src={row.link != null ? row.link : ""}
                height={40}
                onClick={() => this.openImage(row.link)}
              />
            }
          </>
        ),
      },
      {
        name: "DATE & TIME",
        selector: "date_time",
        cell: (row) => (
          <div>
            {row.date_time != null
              ? format(new Date(row.date_time), "do LLL, hh:mm a")
              : ""}
          </div>
        ),
      },
    ];
    return (
      <div className={s.root}>
        {console.log(this.state.from_date, this.state.to_date)}
        <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>Face-Mask Monitor</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="mb-lg">Face-Mask Monitor</h1>
        <Row>
          <Col sm={12} md={12}>
            <Widget title="">
              <div className="pull-right mt-n-xs">
                <button
                  type="button"
                  className="btn btn-success btn-lg"
                  onClick={(e) => this.exportMaskToCSV()}
                >
                  Export CSV
                </button>
              </div>
              <div
                className="pull-right mt-n-xs mr-md"
                style={{ display: "flex" }}
              >
                <span style={{ marginTop: 15, fontWeight: 900 }}>FROM</span>
                <Input
                  type="date"
                  max={new Date().toISOString().substring(0, 10)}
                  value={this.state.from_date}
                  onChange={(e) =>
                    this.onChange(e.target.value, this.state.to_date)
                  }
                ></Input>
                <span
                  style={{ marginTop: 15, fontWeight: 900, marginLeft: 20 }}
                >
                  TO
                </span>
                {console.log(this.state.maxDate)}
                <Input
                  type="date"
                  max={new Date().toISOString().substring(0, 10)}
                  value={this.state.to_date}
                  onChange={(e) => {
                    this.onChange(this.state.from_date, e.target.value);
                  }}
                ></Input>
              </div>
              <DataTable
                className="dataTables_wrapper"
                keyField="_id"
                columns={columns}
                data={this.props.facemasks}
                pagination
                paginationPerPage={10}
                customStyles={customStyles}
              />
            </Widget>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenImage}
          toggle={() => this.closeImage()}
          onClosed={() => this.closeImage()}
        >
          <ModalHeader style={{ color: "#080e33" }}>Entry Door</ModalHeader>
          <ModalBody>
            <img src={this.state.selectedImage} width={470} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.closeImage()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.unsafe.isFetching,
    facemasks: state.unsafe.facemask,
  };
}

export default connect(mapStateToProps)(Facemask);
