import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
  Col,
  Form,
} from "reactstrap";
import s from "./Login.module.scss";
import Widget from "../../components/Widget";
import Footer from "../../components/Footer";
import { loginUser } from "../../actions/user";
import jwt from "jsonwebtoken";
import config from "../../config";
import ls from "../../images/login_side.png";
import logo from "../../images/logo.png";

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isFetching: PropTypes.bool,
    location: PropTypes.any, // eslint-disable-line
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    isAuthenticated: false,
    isFetching: false,
    location: {},
    errorMessage: null,
  };

  static isAuthenticated(token) {
    // We check if app runs with backend mode
    if (!config.isBackend && token) return true;
    if (!token) return;
    const date = new Date().getTime() / 1000;
    const data = jwt.decode(token);
    console.log(date, data.iat);
    return date > 0;
  }

  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
    };
  }

  changeLogin = (event) => {
    this.setState({ login: event.target.value });
  };

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  doLogin = (e) => {
    this.props.dispatch(
      loginUser({
        login: this.state.login,
        password: this.state.password,
      })
    );
    e.preventDefault();
  };

  render() {
    const { from } = this.props.location.state || {
      from: { pathname: "/app" },
    };

    if (this.props.isAuthenticated) {
      // cant access login page while logged in
      return <Redirect to={from} />;
    }

    return (
      <div className={s.root}>
        <Row className="align-items-center">
          <Col
            xs={{ size: 6, offset: 1 }}
            sm={{ size: 6, offset: 3 }}
            lg={{ size: 6, offset: 1 }}
          >
            <img src={logo} alt="logo" />
            <img src={ls} alt="login side image" className="img-fluid" />
          </Col>
          <Col
            xs={{ size: 4, offset: 1 }}
            sm={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 1 }}
          >
            <section className="login-card">
              <h4 className="mt-0">Sign In to ContaVision</h4>
              <p className="fs-sm text-muted">
                User your username and password to sign in
                <br />
                Don&#39;t have an account? Sign up now!
              </p>
              <Form className="mt" onSubmit={this.doLogin}>
                {this.props.errorMessage && (
                  <Alert size="sm" color="danger">
                    {this.props.errorMessage}
                  </Alert>
                )}
                <FormGroup className="form-group">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-envelope"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={this.state.login}
                      onChange={this.changeLogin}
                      type="text"
                      required
                      name="username"
                      placeholder="Username"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-lock" style={{ fontSize: 22 }}></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={this.state.password}
                      onChange={this.changePassword}
                      type="password"
                      required
                      name="password"
                      placeholder="Password"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="d-flex justify-content-end">
                  {/* <a href="#" className="fs-sm">Trouble with account?</a> */}{" "}
                  {/* eslint-disable-line */}
                  <Button color="success" size="sm" type="submit">
                    {this.props.isFetching ? "Loading..." : "Login"}
                  </Button>
                </div>
              </Form>
            </section>
          </Col>
        </Row>
        <Footer className="text-center" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
