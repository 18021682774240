import { getApi } from "../util/Service";
export const FETCH_ATTENDANCE_REQUEST = 'FETCH_ATTENDANCE_REQUEST';
export const FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS';
export const FETCH_ATTENDANCE_FAILURE = 'FETCH_ATTENDANCE_FAILURE';


function requestFetchAttendance() {
  return {
    type: FETCH_ATTENDANCE_REQUEST,
    isFetching: true,
  };
}

function fetchAttendanceSuccess(attendance) {
  return {
    type: FETCH_ATTENDANCE_SUCCESS,
    isFetching: false,
    attendance,
  };
}

function fetchAttendanceError(message) {
  return {
    type: FETCH_ATTENDANCE_FAILURE,
    isFetching: false,
    message,
  };
}

export function fetchAttendance(date) {
  
  return dispatch => {
    dispatch(requestFetchAttendance());

    getApi('/api/attendance')
      .then(attendance => {
        //console.log(attendance)
        dispatch(fetchAttendanceSuccess(attendance.data.data))
      })
      .catch(err => {
        dispatch(fetchAttendanceError("Login failed"));
      })
  };
}
