import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import LinksGroup from "./LinksGroup/LinksGroup";
import logo from "../../images/logo.png";
import s from "./Sidebar.module.scss";

const Sidebar = () => (
  <nav className={s.root}>
    <header className={s.logo}>
      <Link to="/app/main">
        <img src={logo} alt="Logo" />
      </Link>
    </header>
    <ul className={s.nav}>
      <LinksGroup
        header="Insights"
        headerLink="/app/components/charts"
        glyph="insights"
      />
      <LinksGroup
        header="Region of Interest"
        headerLink="/app/roi"
        glyph="components"
      />
      <LinksGroup
        header="Attendance Sheet"
        headerLink="/app/main"
        glyph="attandancesheet"
      />
      <LinksGroup
        header="Social Distance"
        headerLink="/app/contatrack"
        glyph="contatracing"
      />
      <LinksGroup
        header="Intruder Detection"
        headerLink="/app/unsafe"
        glyph="unsafe"
      />
      <LinksGroup
        header="Occupancy Monitor"
        headerLink="/app/occupancy"
        glyph="occupancy"
      />
      <LinksGroup
        header="Hard-Hat Monitor"
        headerLink="/app/hardhat"
        glyph="Hardhat"
      />
      <LinksGroup
        header="Face-Mask Monitor"
        headerLink="/app/facemask"
        glyph="md"
      />
      <LinksGroup
        header="Camera Live"
        headerLink="/app/liveview"
        glyph="cv"
      />
      <LinksGroup 
        header="LPR" 
        headerLink="/app/numberplate" 
        glyph="licenceplate" />
      <LinksGroup 
        header="Ticket" 
        headerLink="/app/ticket" 
        glyph="ticket" />
    </ul>
  </nav>
);

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
