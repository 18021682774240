import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Alert,
  Button,
  ButtonGroup,
  Breadcrumb,
  BreadcrumbItem,
  Progress,
  Badge,
  ListGroup,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input,
  Table,
} from "reactstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { mock } from "./mock";
import user from "../../images/user.png";
import Widget from "../../components/Widget";
//import mqtt from 'mqtt';
import customStyles from "../../styles/customDataTableStyles";

import { fetchAttendance } from "../../actions/attendance";
import s from "./Dashboard.module.scss";
import { format } from "date-fns";
//var mqtt = require('mqtt')
class Dashboard extends Component {
  /* eslint-disable */
  static propTypes = {
    attendance: PropTypes.any,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    attendance: [],
    isFetching: false,
  };

  state = {
    isDropdownOpened: false,
    from_date: format(new Date(), "yyyy-MM-dd"),
    to_date: format(new Date(), "yyyy-MM-dd"),
  };

  componentDidMount() {
    const req = {
      from_date: format(new Date(), "yyyy-MM-dd"),
      to_date: format(new Date(), "yyyy-MM-dd"),
    };
    this.props.dispatch(fetchAttendance(req));
  }

  formatDate = (str) => {
    return str.replace(/,.*$/, "");
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpened: !prevState.isDropdownOpened,
    }));
  };

  onChange = (date1, date2) => {
    //console.log(date1, date2)
    this.setState({
      from_date: date1,
      to_date: date2,
    });
    const req = {
      from_date: date1,
      to_date: date2,
    };
    this.props.dispatch(fetchAttendance(req));
  };

  exportAttendanceToCSV = () => {
    let rows = [["ID", "NAME", "USERNAME", "CHECK-IN", "CHECK-OUT"]];
    this.props.attendance.map((res, i) => {
      rows[i + 1] = new Array(
        res.id,
        res.name,
        res.email,
        format(new Date(res.checkin), "do LLL hh:mm a"),
        res.checkout != null
          ? format(new Date(res.checkout), "do LLL, hh:mm a")
          : ""
      );
    });
    console.log(rows);
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `attendance.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  render() {
    const columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "NAME",
        selector: "name",
        sortable: true,
      },
      {
        name: "PICTURE",
        selector: "profile_pic",
        cell: (row) => (
          <img
            src={row.photo != null ? row.photo : user}
            className="rounded-circle"
            width={40}
            height={40}
          />
        ),
      },
      {
        name: "USERNAME",
        selector: "email",
        sortable: true,
      },
      {
        name: "CHECK-IN",
        selector: "checkin",
        sortable: true,
        cell: (row) => (
          <div>
            {row.checkin != null
              ? format(new Date(row.checkin), "do LLL, hh:mm a")
              : ""}
          </div>
        ),
      },
      {
        name: "CHECK-OUT",
        selector: "checkout",
        sortable: true,
        cell: (row) => (
          <div>
            {row.checkout != null
              ? format(new Date(row.checkout), "do LLL, hh:mm a")
              : ""}
          </div>
        ),
      },
      {
        name: "STATUS",
        selector: "status",
        sortable: true,
        cell: (row) => (
          <Button size="sm" color="success" className="mr-sm mb-xs">
            Active
          </Button>
        ),
      },
    ];
    return (
      <div className={s.root}>
        {console.log(this.state.from_date, this.state.to_date)}
        <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>Attendance Sheet</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="mb-lg">Attendance Sheet</h1>
        <Row>
          <Col sm={12} md={12}>
            <Widget
              title={
                <div>
                  <div className="pull-right mt-n-xs">
                    <button
                      type="button"
                      className="btn btn-success btn-lg"
                      onClick={(e) => this.exportAttendanceToCSV()}
                    >
                      Export CSV
                    </button>
                  </div>
                  <div
                    className="pull-right mt-n-xs mr-md"
                    style={{ display: "flex" }}
                  >
                    <span style={{ marginTop: 15, fontWeight: 900 }}>FROM</span>
                    <Input
                      type="date"
                      max={new Date().toISOString().substring(0, 10)}
                      value={this.state.from_date}
                      onChange={(e) =>
                        this.onChange(e.target.value, this.state.to_date)
                      }
                    ></Input>
                    <span
                      style={{ marginTop: 15, fontWeight: 900, marginLeft: 20 }}
                    >
                      TO
                    </span>
                    {console.log(this.state.maxDate)}
                    <Input
                      type="date"
                      max={new Date().toISOString().substring(0, 10)}
                      value={this.state.to_date}
                      onChange={(e) => {
                        this.onChange(this.state.from_date, e.target.value);
                      }}
                    ></Input>
                  </div>
                  <h5 className="mt-0 mb-3">
                    <i className="fa fa-user mr-xs opacity-70" /> Users
                    Attendance
                  </h5>
                </div>
              }
            >
              <DataTable
                keyField="_id"
                columns={columns}
                data={this.props.attendance}
                pagination
                paginationPerPage={10}
                customStyles={customStyles}
              />
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.attendance.isFetching,
    attendance: state.attendance.attendance,
  };
}

export default connect(mapStateToProps)(Dashboard);
