import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

class SimpleBarChart extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      date_time: PropTypes.string,
      car_id: PropTypes.number,
      car_speed: PropTypes.number,
    })).isRequired,
  }

	render () {
  	return (
      <ResponsiveContainer height={300} width='100%'>
        <BarChart 
          width={600} 
          height={300} 
          data={this.props.data} 
          margin={{top: 20, left: -10}}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="date_time"/>
          <YAxis yAxisId="left" orientation="left" stroke="#6b45fe"/>
          <YAxis yAxisId="right" orientation="right" stroke="#00C49F"/>
          <Tooltip/>
          <Legend />
          <Bar yAxisId="left" dataKey="car_id" fill="#6b45fe" />
          <Bar yAxisId="right" dataKey="car_speed" fill="#00C49F" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default SimpleBarChart;
