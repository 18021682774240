import { postAuth, postConta, postContaAuth } from "../util/Service";
import { format, addDays, subDays } from 'date-fns';
export const FETCH_CONTA_REQUEST = 'FETCH_CONTA_REQUEST';
export const FETCH_CONTA_SUCCESS = 'FETCH_CONTA_SUCCESS';
export const FETCH_CONTA_FAILURE = 'FETCH_CONTA_FAILURE';

export const FETCH_CONTA_SAFETY_REQUEST = 'FETCH_CONTA_SAFETY_REQUEST';
export const FETCH_CONTA_SAFETY_SUCCESS = 'FETCH_CONTA_SAFETY_SUCCESS';
export const FETCH_CONTA_SAFETY_FAILURE = 'FETCH_CONTA_SAFETY_FAILURE';

export const FETCH_CONTA_7DAY_REQUEST = 'FETCH_CONTA_7DAY_REQUEST';
export const FETCH_CONTA_7DAY_SUCCESS = 'FETCH_CONTA_7DAY_SUCCESS';
export const FETCH_CONTA_7DAY_FAILURE = 'FETCH_CONTA_7DAY_FAILURE';

export const FETCH_CONTA_TREND_REQUEST = 'FETCH_CONTA_TREND_REQUEST';
export const FETCH_CONTA_TREND_SUCCESS = 'FETCH_CONTA_TREND_SUCCESS';
export const FETCH_CONTA_TREND_FAILURE = 'FETCH_CONTA_TREND_FAILURE';


function requestFetchConta() {
  return {
    type: FETCH_CONTA_REQUEST,
    isFetching: true,
  };
}

function fetchContaSuccess(conta) {
  return {
    type: FETCH_CONTA_SUCCESS,
    isFetching: false,
    conta,
  };
}

function fetchContaError(message) {
  return {
    type: FETCH_CONTA_FAILURE,
    isFetching: false,
    message,
  };
}

function requestFetchContaSafety() {
  return {
    type: FETCH_CONTA_SAFETY_REQUEST,
    isFetching: true,
  };
}

function fetchContaSafetySuccess(safety) {
  return {
    type: FETCH_CONTA_SAFETY_SUCCESS,
    isFetching: false,
    safety,
  };
}

function fetchContaSafetyError(message) {
  return {
    type: FETCH_CONTA_SAFETY_FAILURE,
    isFetching: false,
    message,
  };
}

function requestFetch7Day() {
  return {
    type: FETCH_CONTA_7DAY_REQUEST,
    isFetching: true,
  };
}

function fetch7DaySuccess(report) {
  return {
    type: FETCH_CONTA_7DAY_SUCCESS,
    isFetching: false,
    report,
  };
}

function fetch7DayError(message) {
  return {
    type: FETCH_CONTA_7DAY_FAILURE,
    isFetching: false,
    message,
  };
}

function requestFetchTrend() {
  return {
    type: FETCH_CONTA_TREND_REQUEST,
    isFetching: true,
  };
}

function fetchTrendSuccess(trends) {
  return {
    type: FETCH_CONTA_TREND_SUCCESS,
    isFetching: false,
    trends,
  };
}

function fetchTrendError(message) {
  return {
    type: FETCH_CONTA_TREND_FAILURE,
    isFetching: false,
    message,
  };
}

export function fetchConta(data) {
  
  return dispatch => {
    dispatch(requestFetchConta());

    postContaAuth('/api/cross/history/company', data)
      .then(conta => {
        console.log(conta)
        dispatch(fetchContaSuccess(conta.data.result.data))
      })
      .catch(err => {
        dispatch(fetchContaError("Fetch Data failed"));
      })
  };
}

export function fetchContaSafety(data) {
  
  return dispatch => {
    dispatch(requestFetchContaSafety());

    postContaAuth('/api/cross/safety-indicator', data)
      .then(conta => {
        console.log(conta)
        dispatch(fetchContaSafetySuccess(conta.data.result))
      })
      .catch(err => {
        dispatch(fetchContaSafetyError("Fetch Data failed"));
      })
  };
}

export function fetch7DayReport(data) {
    return dispatch => {
      dispatch(requestFetch7Day());
  
      postContaAuth('/api/cross/7day-report', data)
        .then(report => {
          console.log(report.data.result)
          let arr = [];
          report.data.result.reverse().map((breach, i) => {
            arr.push({ to_day: format(addDays(new Date(breach.to_day),-1), 'dd/MM'), total: breach.total});
          })
          console.log(arr)
          dispatch(fetch7DaySuccess(arr))
        })
        .catch(err => {
          dispatch(fetch7DayError("Fetch Data failed"));
        })
    };
}

export function fetchTrend(data) {
  return dispatch => {
    dispatch(requestFetchTrend());

    postContaAuth('/api/breach/trends', data)
      .then(trends => {
        console.log(trends.data.result)
        
        dispatch(fetchTrendSuccess(trends.data.data))
      })
      .catch(err => {
        dispatch(fetchTrendError("Fetch Data failed"));
      })
  };
}