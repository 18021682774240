import React, { PureComponent } from "react";
import Chart from "react-google-charts";
import { addDays, format } from "date-fns";
import { fetchTrend } from "../../../actions/contatrack";
import { Input } from "reactstrap";
import { connect } from "react-redux";
import { postContaAuth } from "../../../util/Service";
class BreachTrend extends PureComponent {
  constructor() {
    super();
    this.state = {
      data: [
        ["Time", "Number of breaches"],
        ["10 am", 0],
      ],
      showDays: "1",
      isLoading: false,
    };
  }
  componentDidMount() {
    this.getTrends();
    console.log(
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      "current time zone ================== >>>>>>>>>>>"
    );
  }
  getTrends = (cat) => {
    let data = {};

    switch (this.state.showDays) {
      case "1":
        data = {
          fromDate: format(new Date(), "yyyy-MM-dd"),
          toDate: format(addDays(new Date(), 1), "yyyy-MM-dd"),
          isToday: true,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        break;
      case "7":
        data = {
          fromDate: format(addDays(new Date(), -6), "yyyy-MM-dd"),
          toDate: format(addDays(new Date(), 1), "yyyy-MM-dd"),
          isToday: false,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        break;
      case "14":
        data = {
          fromDate: format(addDays(new Date(), -13), "yyyy-MM-dd"),
          toDate: format(addDays(new Date(), 1), "yyyy-MM-dd"),
          isToday: false,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        break;
    }

    postContaAuth("/api/breach/trends", data).then((res) => {
      // console.log(res);
      const status = res.request.status;
      if (status === 200) {
        if (res.data.errorCode) {
          this.props.signout();
          return;
        }
        res.data.data.map((trent, i) => {
          // console.log(trent[1])
          trent[1] = parseInt(trent[1]);
          trent[0] = trent[0] + ":00";
          return;
        });
        res.data.data.unshift(["Time", "Number of breaches"]);
        // console.log(res.data.data)
        this.setState({
          data: res.data.data,
          isLoading: false,
        });
      }
    });
  };
  render() {
    return (
      <div className="graph-box">
        <div className="topgraphheading">
          <div className="exportdataprt">
            <Input
              type="select"
              className="form-control input-sm"
              style={{ width: 200 }}
              onChange={(e) => {
                this.setState({ showDays: e.target.value }, () => {
                  this.getTrends();
                });
              }}
            >
              <option value={1}>Today</option>
              <option value={7}>7 days</option>
              <option value={14}>14 days</option>
            </Input>
          </div>
        </div>
        {/* <img src={`${process.env.PUBLIC_URL}/assets/images/graphimg3.png`} /> */}
        <div className="grapContainer" style={{ width: 600 }}>
          <Chart
            width={"600px"}
            height={"400px"}
            className="breach-trend"
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={this.state.data}
            options={{
              backgroundColor: "transparent",
              colors: ["#4285f4", "#FFFFFF", "#FFFFFF"],
              legendTextStyle: { color: "#FFF" },
              titleTextStyle: { color: "#FFF" },
              textStyle: { color: "#FFF" },
              hAxis: {
                title: "Time",
                textStyle: { color: "#FFF" },
              },
              vAxis: {
                title: "Number of breaches",
                textStyle: { color: "#FFF" },
              },
              series: {
                1: { curveType: "function" },
              },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.contatrack.isFetching,
    trends: state.contatrack.trends,
  };
}

export default connect(mapStateToProps)(BreachTrend);
