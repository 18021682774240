import { getApi, postApi } from "../util/Service";
import { format, addDays, subDays } from 'date-fns';
export const FETCH_UNSAFE_REQUEST = 'FETCH_UNSAFE_REQUEST';
export const FETCH_UNSAFE_SUCCESS = 'FETCH_UNSAFE_SUCCESS';
export const FETCH_UNSAFE_FAILURE = 'FETCH_UNSAFE_FAILURE';

export const FETCH_UNSAFE_7DAY_REQUEST = 'FETCH_UNSAFE_7DAY_REQUEST';
export const FETCH_UNSAFE_7DAY_SUCCESS = 'FETCH_UNSAFE_7DAY_SUCCESS';
export const FETCH_UNSAFE_7DAY_FAILURE = 'FETCH_UNSAFE_7DAY_FAILURE';

export const FETCH_PEOPLE_REQUEST = 'FETCH_PEOPLE_REQUEST';
export const FETCH_PEOPLE_SUCCESS = 'FETCH_PEOPLE_SUCCESS';
export const FETCH_PEOPLE_FAILURE = 'FETCH_PEOPLE_FAILURE';

export const FETCH_HAT_REQUEST = 'FETCH_HAT_REQUEST';
export const FETCH_HAT_SUCCESS = 'FETCH_HAT_SUCCESS';
export const FETCH_HAT_FAILURE = 'FETCH_HAT_FAILURE';

export const FETCH_MASK_REQUEST = 'FETCH_MASK_REQUEST';
export const FETCH_MASK_SUCCESS = 'FETCH_MASK_SUCCESS';
export const FETCH_MASK_FAILURE = 'FETCH_MASK_FAILURE';


function requestFetchUnsafe() {
  return {
    type: FETCH_UNSAFE_REQUEST,
    isFetching: true,
  };
}

function fetchUnsafeSuccess(unsafe) {
  return {
    type: FETCH_UNSAFE_SUCCESS,
    isFetching: false,
    unsafe,
  };
}

function fetchUnsafeError(message) {
  return {
    type: FETCH_UNSAFE_FAILURE,
    isFetching: false,
    message,
  };
}

function requestFetch7Day() {
  return {
    type: FETCH_UNSAFE_7DAY_REQUEST,
    isFetching: true,
  };
}

function fetch7DaySuccess(report) {
  return {
    type: FETCH_UNSAFE_7DAY_SUCCESS,
    isFetching: false,
    report,
  };
}

function fetch7DayError(message) {
  return {
    type: FETCH_UNSAFE_7DAY_FAILURE,
    isFetching: false,
    message,
  };
}

function requestFetchPeople() {
  return {
    type: FETCH_PEOPLE_REQUEST,
    isFetching: true,
  };
}

function fetchPeopleSuccess(people) {
  return {
    type: FETCH_PEOPLE_SUCCESS,
    isFetching: false,
    people,
  };
}

function fetchPeopleError(message) {
  return {
    type: FETCH_PEOPLE_FAILURE,
    isFetching: false,
    message,
  };
}


function requestFetchHat() {
  return {
    type: FETCH_HAT_REQUEST,
    isFetching: true,
  };
}

function fetchHatSuccess(hardhat) {
  return {
    type: FETCH_HAT_SUCCESS,
    isFetching: false,
    hardhat,
  };
}

function fetchHatError(message) {
  return {
    type: FETCH_HAT_FAILURE,
    isFetching: false,
    message,
  };
}

function requestFetchMask() {
  return {
    type: FETCH_MASK_REQUEST,
    isFetching: true,
  };
}

function fetchMaskSuccess(facemask) {
  return {
    type: FETCH_MASK_SUCCESS,
    isFetching: false,
    facemask,
  };
}

function fetchMaskError(message) {
  return {
    type: FETCH_MASK_FAILURE,
    isFetching: false,
    message,
  };
}

export function fetchUnsafe(req) {
  
  return dispatch => {
    dispatch(requestFetchUnsafe());

    postApi('/api/unsafe', req)
      .then(unsafe => {
        console.log(unsafe.data.data)
        dispatch(fetchUnsafeSuccess(unsafe.data.data))
      })
      .catch(err => {
        dispatch(fetchUnsafeError("Fetch Data failed"));
      })
  };
}

export function fetch7DayReport() {
  return dispatch => {
    dispatch(requestFetch7Day());

    postApi('/api/unsafe/7day-report', {})
      .then(report => {
        let arr = [];
        report.data.data.reverse().map(report => {
          arr.push({ to_day: format(addDays(new Date(report.to_day),-1), 'dd/MM'), total: report.total});
        })
        //console.log(arr)
        dispatch(fetch7DaySuccess(arr))
      })
      .catch(err => {
        dispatch(fetch7DayError("Fetch Data failed"));
      })
  };
}

export function fetchPeople(req) {
  return dispatch => {
    dispatch(requestFetchPeople());

    postApi('/api/people',req)
      .then(people => {
        console.log(people.data.data)
        dispatch(fetchPeopleSuccess(people.data.data))
      })
      .catch(err => {
        dispatch(fetchPeopleError("Fetch Data failed"));
      })
  };
}

export function fetch7DayPeople() {
  return dispatch => {
    dispatch(requestFetch7Day());

    postApi('/api/people/7day-report', {})
      .then(report => {
        let arr = [];
        report.data.data.reverse().map(report => {
          arr.push({ to_day: format(addDays(new Date(report.to_day),-1), 'dd/MM'), total: report.total});
        })
        //console.log(arr)
        dispatch(fetch7DaySuccess(arr))
      })
      .catch(err => {
        dispatch(fetch7DayError("Fetch Data failed"));
      })
  };
}

export function fetchHat(req) {
  return dispatch => {
    dispatch(requestFetchHat());

    postApi('/api/hardhat', req)
      .then(hardhat => {
        console.log(hardhat.data.data)
        dispatch(fetchHatSuccess(hardhat.data.data))
      })
      .catch(err => {
        dispatch(fetchHatError("Fetch Data failed"));
      })
  };
}

export function fetch7DayHat() {
  return dispatch => {
    dispatch(requestFetch7Day());

    postApi('/api/hardhat/7day-report', {})
      .then(report => {
        let arr = [];
        report.data.data.reverse().map(report => {
          arr.push({ to_day: format(addDays(new Date(report.to_day),-1), 'dd/MM'), total: report.total});
        })
        //console.log(arr)
        dispatch(fetch7DaySuccess(arr))
      })
      .catch(err => {
        dispatch(fetch7DayError("Fetch Data failed"));
      })
  };
}

export function fetchMask(req) {
  
  return dispatch => {
    dispatch(requestFetchMask());

    postApi('/api/facemask', req)
      .then(facemask => {
        console.log(facemask.data.data)
        dispatch(fetchMaskSuccess(facemask.data.data))
      })
      .catch(err => {
        dispatch(fetchMaskError("Fetch Data failed"));
      })
  };
}

export function fetch7DayMask() {
  return dispatch => {
    dispatch(requestFetch7Day());

    postApi('/api/facemask/7day-report', {})
      .then(report => {
        let arr = [];
        report.data.data.reverse().map(report => {
          arr.push({ to_day: format(addDays(new Date(report.to_day),-1), 'dd/MM'), total: report.total});
        })
        //console.log(arr)
        dispatch(fetch7DaySuccess(arr))
      })
      .catch(err => {
        dispatch(fetch7DayError("Fetch Data failed"));
      })
  };
}