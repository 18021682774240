import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { format, subDays, addDays } from "date-fns";
import { fetchConta } from "../../../actions/contatrack";
import { postContaAuth } from "../../../util/Service";
class Infected extends PureComponent {
  constructor() {
    super();
    this.state = {
      infected: 0,
      total: 0,
    };
  }
  static defaultProps = {
    conta: [],
    isFetching: false,
  };
  componentDidMount() {
    const data = {
      from_date: format(new Date(), "yyyy-MM-dd"),
      to_date: format(addDays(new Date(), 1), "yyyy-MM-dd"),
    };
    this.props.dispatch(fetchConta(data));
    this.getInfected();
  }
  getInfected = () => {
    let data = {};
    postContaAuth("/api/employee/infected", data).then((res) => {
      // console.log(res);
      const status = res.request.status;
      if (status === 200) {
        this.setState({
          infected: res.data.result,
          isLoading: false,
        });
      }
    });
  };
  render() {
    return (
      <div className="container infectedCard">
        <div
          className="itmes"
          style={{ marginTop: "70px", marginBottom: "40px" }}
        >
          <h1>
            {" "}
            <span style={{ fontSize: "70px" }}>
              {this.props.conta.length}
            </span>{" "}
            Breach Today
          </h1>
          <h1>
            {" "}
            <span style={{ fontSize: "70px" }}>{this.state.infected}</span>{" "}
            Known Infected
          </h1>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.contatrack.isFetching,
    conta: state.contatrack.conta,
  };
}

export default connect(mapStateToProps)(Infected);
