import { patchApi, postApi, getApi } from "../util/Service";
import { format, addDays, subDays } from 'date-fns';

export const FETCH_UNSAFE_TICKET_REQUEST = 'FETCH_UNSAFE_TICKET_REQUEST';
export const FETCH_UNSAFE_TICKET_SUCCESS = 'FETCH_UNSAFE_TICKET_SUCCESS';
export const FETCH_UNSAFE_TICKET_FAILURE = 'FETCH_UNSAFE_TICKET_FAILURE';

export const CHANGE_TICKET_SUCCESS = 'CHANGE_TICKET_SUCCESS';
export const GET_TICKET_STATUS_SUCCESS = 'GET_TICKET_STATUS_SUCCESS';
export const GET_TICKET_LOG_SUCCESS = 'GET_TICKET_LOG_SUCCESS';
export const GET_TICKET_DETAILS_SUCCESS = 'GET_TICKET_DETAILS_SUCCESS';
export const GET_TICKET_BREACH_SUCCESS = 'GET_TICKET_BREACH_SUCCESS';


function requestFetchUnsafeTicket() {
  return {
    type: FETCH_UNSAFE_TICKET_REQUEST,
    isFetching: true,
  };
}

function fetchUnsafeTicketSuccess(tickets) {
  return {
    type: FETCH_UNSAFE_TICKET_SUCCESS,
    isFetching: false,
    tickets,
  };
}

function fetchUnsafeTicketError(message) {
  return {
    type: FETCH_UNSAFE_TICKET_FAILURE,
    isFetching: false,
    message,
  };
}

function changeTicketSuccess(ticket) {
  return {
    type: CHANGE_TICKET_SUCCESS,
    isFetching: false,
    ticket,
  };
}

function getTicketStatusSuccess(status) {
  return {
    type: GET_TICKET_STATUS_SUCCESS,
    isFetching: false,
    status,
  };
}

function getTicketLogSuccess(logs) {
  return {
    type: GET_TICKET_LOG_SUCCESS,
    isFetching: false,
    logs,
  };
}

function getTicketDetailsSuccess(ticket) {
  return {
    type: GET_TICKET_DETAILS_SUCCESS,
    isFetching: false,
    ticket,
  };
}

function getTicketBreachSuccess(breaches) {
  return {
    type: GET_TICKET_BREACH_SUCCESS,
    isFetching: false,
    breaches,
  };
}

export function fetchUnsafeTicket(data) {
  return dispatch => {
    dispatch(requestFetchUnsafeTicket());

    postApi('/api/ticket', data)
      .then(tickets => {
        dispatch(fetchUnsafeTicketSuccess(tickets.data.data))
      })
      .catch(err => {
        dispatch(fetchUnsafeTicketError("Fetch Data failed"));
      })
  };
}


export function changeTicket(id, status) {
  return dispatch => {
    patchApi('/api/ticket/'+id, {status})
      .then(ticket => {
        dispatch(changeTicketSuccess(ticket.data.data))
      })
      .catch(err => {
        dispatch(fetchUnsafeTicketError("Delete Data failed"));
      })
  };
}

export function getTicketStatus(data) {
  return dispatch => {
    postApi('/api/ticket/status', data)
      .then(status => {
        dispatch(getTicketStatusSuccess(status.data.data))
      })
      .catch(err => {
        dispatch(fetchUnsafeTicketError("Delete Data failed"));
      })
  };
}

export function getTicketLog(id) {
  return dispatch => {
    getApi('/api/log/'+id)
      .then(logs => {
        dispatch(getTicketLogSuccess(logs.data.data))
      })
      .catch(err => {
        dispatch(fetchUnsafeTicketError("Fetch Data failed"));
      })
  };
}

export function getTicketDetails(id) {
  return dispatch => {
    getApi('/api/ticket/'+id)
      .then(ticket => {
        dispatch(getTicketDetailsSuccess(ticket.data.data))
      })
      .catch(err => {
        dispatch(fetchUnsafeTicketError("Fetch Data failed"));
      })
  };
}

export function getTicketBreach(id) {
  return dispatch => {
    getApi('/api/breach/by-ticket/'+id)
      .then(breaches => {
        dispatch(getTicketBreachSuccess(breaches.data.data))
      })
      .catch(err => {
        dispatch(fetchUnsafeTicketError("Fetch Data failed"));
      })
  };
}