import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Input
} from "reactstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import Widget from "../../components/Widget/Widget";
//import mqtt from 'mqtt';
import customStyles from "../../styles/customDataTableStyles";
import { fetchUnsafeTicket, changeTicket, getTicketStatus } from "../../actions/ticket";
import s from "./Ticket.module.scss";
import { format, subDays, addDays } from "date-fns";
import Icon from "../../components/Icon";

class Ticket extends Component {
  /* eslint-disable */
  static propTypes = {
    tickets: PropTypes.any,
    status: PropTypes.any,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    tickets: [],
    status: null,
    isFetching: false,
  };

  state = {
    from_date: format(new Date(), "yyyy-MM-dd"),
    to_date: format(new Date(), "yyyy-MM-dd"),
    data: [],
    selectedItems: [],
    days: 15,
    status: 'open',
    algorithm: undefined
  };

  componentDidMount() {
    const req = {
      days: this.state.days,
      status: this.state.status,
      algorithm: this.state.algorithm
    }
    this.props.dispatch(fetchUnsafeTicket(req));
    this.props.dispatch(getTicketStatus(req));
  }
  componentWillReceiveProps(nextProps){
    this.setState({
        data:nextProps.tickets
    })
  }

  select(data) {
    console.log(data)
    this.setState({
      data: this.props.tickets,
      selectedItems: data.selectedRows
    })
  }

  delete() {
    this.state.selectedItems.map(selectedItem => {
      new Promise((resolve, reject) =>{
        this.props.dispatch(changeTicket(selectedItem._id, 'archived'))
        resolve()
      }).then(res => {
        const req = {
          days: this.state.days,
          status: this.state.status,
          algorithm: this.state.algorithm
        }
        this.props.dispatch(fetchUnsafeTicket(req));
        this.props.dispatch(getTicketStatus(req));
      })
    })
    /* const arr = this.state.selectedItems.map(selectedItem => {
      console.log(selectedItem._id)
      this.state.data.map((item, index) => {
        if (item._id == selectedItem._id)
          this.props.dispatch(deleteTicket(item._id))
          this.state.data.splice(index, 1)
      })
    })
    this.setState({
      data: this.state.data
    }) */
  }

  changeDay(val) {
    this.setState({
      days: val
    })
    const req = {
      days: val,
      status: this.state.status,
      algorithm: this.state.algorithm
    }
    this.props.dispatch(fetchUnsafeTicket(req));
  }

  changeAlgo(val) {
    this.setState({
      algorithm: val
    })
    const req = {
      algorithm: val,
      days: this.state.days,
      status: this.state.status
    }
    this.props.dispatch(fetchUnsafeTicket(req));
  }

  onRedirect(rowData) {
    console.log(rowData)
    this.props.history.push('/app/ticket/'+rowData._id)
  }

  exportToCSV = () => {
    let rows = [["TICKET NAME", "TASK", "CAMERA NAME", "STATUS", "CREATED AT", "ASSIGNEE"]];
    this.state.data.map((res, i) => {
      rows[i + 1] = new Array(
        res.ticket_name,
        res.ticket_detail,
        res.camera_name,
        res.status,
        format(new Date(res.last_check), "do LLL hh:mm a"),
        res.assignee,
      );
    });

    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `tickets.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  };


  render() {
    console.log(this.props.status)
    const columns = [
      {
        name: "TICKET NAME",
        selector: "ticket_name"
      },
      {
        name: "TASK",
        selector: "ticket_detail"
      },
      {
        name: "CAMERA NAME",
        selector: "camera_name"
      },
      {
        name: "STATUS",
        selector: "status"
      },
      {
        name: "CREATED AT",
        selector: "last_check",
        cell: (row) => (
          <div>
            {row.last_check != null
              ? format(new Date(row.last_check), "do LLL, hh:mm a")
              : ""}
          </div>
        ),
      },
      {
        name: "ASSIGNEE",
        selector: "assignee",
        cell: (row) => (<div>Admin</div>)
      },
    ];
    return (
      <div className={s.root}>
        <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>Ticket</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="mb-lg">Ticket</h1>
        <Row>
          <Col sm={4} md={4}>
            <Widget>
              <Icon glyph="components" />
              <div style={{display: 'inline-grid', marginLeft: 15}}>
                <span style={{fontSize:24}}>{this.props.status && this.props.status.all}</span>
                <span>All tickets</span>
              </div>
            </Widget>
          </Col>
          <Col sm={4} md={4}>
            <Widget>
              <Icon glyph="components" />
              <div style={{display: 'inline-grid', marginLeft: 15}}>
                <span style={{fontSize:24}}>{this.props.status && this.props.status.open}</span>
                <span>Open tickets</span>
              </div>
            </Widget>
          </Col>
          <Col sm={4} md={4}>
            <Widget>
              <Icon glyph="components" />
              <div style={{display: 'inline-grid', marginLeft: 15}}>
                <span style={{fontSize:24}}>{this.props.status && this.props.status.archived}</span>
                <span>Archived tickets</span>
              </div>
            </Widget>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <Widget title="">
              <Row>
                <Col md={4}>
                  <Input type="select" className="form-control input-sm" onChange={(e) => this.changeDay(`${e.target.value}`)} >
                    <option value="15">Last 15 Days</option>
                    <option value="30">Last 30 Days</option>
                  </Input>
                </Col>
                <Col md={4}>
                  <Input type="select" className="form-control input-sm" onChange={(e) => this.changeAlgo(`${e.target.value}`)}>
                  <option value="">
                              Filter by algorithm
                            </option>
                            <option value="602f5d1f26fc0a25960c927a">
                              Attendance Monitoring
                            </option>
                            <option value="604b48e88a4e3a30aa3eb060">
                              Speed Detection
                            </option>
                            <option value="606ed9352e39025e1a9f4cfb">
                              Restricted Zone
                            </option>
                            <option value="608fc077a23f94101c5d851f">
                              Occupancy Monitor
                            </option>
                            <option value="60a4d0aadda01944988dc4f3">
                              Hard-Hat Monitor
                            </option>
                            <option value="60b0b04fcea3a667ac7c4235">
                              Face-Mask Monitor
                            </option>
                            <option value="60cb154db5ea6632c8583ba4">
                              Number Plate Detection
                            </option>
                  </Input>
                </Col>
                <Col md={4}>
                  <Button onClick={() => this.delete()}>Archive</Button>
                  <Button onClick={() => this.exportToCSV()}>Export CSV</Button>
                </Col>
              </Row>
              <DataTable
                className="dataTables_wrapper"
                keyField="_id"
                columns={columns}
                data={this.state.data}
                onRowClicked={rowData=> {
                  this.onRedirect(rowData)
                }}
                selectableRows
                noHeader
                onSelectedRowsChange={(data) => this.select(data)}
                pagination
                paginationPerPage={10}
                customStyles={customStyles}
                pointerOnHover={true}
                highlightOnHover={true}
                striped={true}
              />
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.ticket.isFetching,
    tickets: state.ticket.tickets,
    status: state.ticket.status
  };
}

export default connect(mapStateToProps)(Ticket);
