import { getApi, postApi } from "../util/Service";
import { format, addDays, subDays } from 'date-fns';
export const FETCH_NUMBER_PLATE_REQUEST = 'FETCH_NUMBER_PLATE_REQUEST';
export const FETCH_NUMBER_PLATE_SUCCESS = 'FETCH_NUMBER_PLATE_SUCCESS';
export const FETCH_NUMBER_PLATE_FAILURE = 'FETCH_NUMBER_PLATE_FAILURE';

export const FETCH_NUMBER_PLATE_7DAY_REQUEST = 'FETCH_NUMBER_PLATE_7DAY_REQUEST';
export const FETCH_NUMBER_PLATE_7DAY_SUCCESS = 'FETCH_NUMBER_PLATE_7DAY_SUCCESS';
export const FETCH_NUMBER_PLATE_7DAY_FAILURE = 'FETCH_NUMBER_PLATE_7DAY_FAILURE';

function requestFetchNumberPlate() {
  return {
    type: FETCH_NUMBER_PLATE_REQUEST,
    isFetching: true,
  };
}

function fetchNumberPlateSuccess(numberPlate) {
  return {
    type: FETCH_NUMBER_PLATE_SUCCESS,
    isFetching: false,
    numberPlate,
  };
}

function fetchNumberPlateError(message) {
  return {
    type: FETCH_NUMBER_PLATE_FAILURE,
    isFetching: false,
    message,
  };
}

function requestFetch7Day() {
  return {
    type: FETCH_NUMBER_PLATE_7DAY_REQUEST,
    isFetching: true,
  };
}

function fetch7DaySuccess(report) {
  return {
    type: FETCH_NUMBER_PLATE_7DAY_SUCCESS,
    isFetching: false,
    report,
  };
}

function fetch7DayError(message) {
  return {
    type: FETCH_NUMBER_PLATE_7DAY_FAILURE,
    isFetching: false,
    message,
  };
}

export function fetchNumberPlate(req) {
  
  return dispatch => {
    dispatch(requestFetchNumberPlate());

    postApi('/api/numberplate', req)
      .then(numberPlate => {
        console.log(numberPlate.data.data)
        dispatch(fetchNumberPlateSuccess(numberPlate.data.data))
      })
      .catch(err => {
        dispatch(fetchNumberPlateError("Fetch Data failed"));
      })
  };
}

export function fetch7DayReport() {
  return dispatch => {
    dispatch(requestFetch7Day());

    postApi('/api/numberplate/7day-report', {})
      .then(report => {
        let arr = [];
        report.data.data.reverse().map(report => {
          arr.push({ to_day: format(addDays(new Date(report.to_day),-1), 'dd/MM'), total: report.total});
        })
        //console.log(arr)
        dispatch(fetch7DaySuccess(arr))
      })
      .catch(err => {
        dispatch(fetch7DayError("Fetch Data failed"));
      })
  };
}
