import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Alert,
  Button,
  ButtonGroup,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { mock } from "./mock";
import user from "../../images/user.png";
import Widget from "../../components/Widget/Widget";
//import mqtt from 'mqtt';
import customStyles from "../../styles/customDataTableStyles";

import { fetchConta } from "../../actions/contatrack";
import s from "./Contatrack.module.scss";
import { format, subDays, addDays } from "date-fns";
//var mqtt = require('mqtt')
class Contatrack extends Component {
  /* eslint-disable */
  static propTypes = {
    conta: PropTypes.any,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    conta: [],
    isFetching: false,
  };

  state = {
    breaches: [],
    type: "",
    isDropdownOpened: false,
    isOpenImage: false,
    selectedImage: "",
  };

  componentDidMount() {
    const data = {
      from_date: format(new Date(), "yyyy-MM-dd"),
      to_date: format(addDays(new Date(), 1), "yyyy-MM-dd"),
    };
    this.props.dispatch(fetchConta(data));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.conta !== this.props.conta) {
      const list = this.props.conta.filter((conta) => {
        return conta.source === "mobile";
      });
      this.setState({
        breaches: list,
        type: "mobile",
      });
    }
  }

  formatDate = (str) => {
    return str.replace(/,.*$/, "");
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpened: !prevState.isDropdownOpened,
    }));
  };

  onChange = (value) => {
    const data = {
      from_date: format(addDays(new Date(), 1 - value), "yyyy-MM-dd"),
      to_date: format(addDays(new Date(), 1), "yyyy-MM-dd"),
    };
    this.props.dispatch(fetchConta(data));
    const list = this.props.conta.filter((conta) => {
      return conta.source === "mobile";
    });
    this.setState({
      breaches: list,
      type: "mobile",
    });
  };

  onChangeType = (value) => {
    var list = [];
    if (value === "mobile") {
      list = this.props.conta.filter((conta) => {
        console.log(value, conta.source);
        return conta.source === value;
      });
    } else {
      list = this.props.conta.filter((conta) => {
        return conta.source !== "mobile";
      });
    }

    this.setState({
      breaches: list,
      type: value,
    });
  };

  exportAttendanceToCSV = () => {
    let rows = [
      [
        "NAME",
        "ID",
        "COVID-19",
        "BREACH WITH",
        "ID",
        "COVID-19",
        "DISTANCE",
        "SOURCE",
        "DATE",
      ],
    ];
    if (this.state.type === "mobile") {
      var index = 1;
      this.state.breaches.map((res, i) => {
        if (res.source === "mobile") {
          rows[index] = new Array(
            res.employee.length > 0 ? res.employee[0].name : "Guest",
            res.employee.length > 0 ? res.employee[0].employee_id : "N/A",
            res.employee.length > 0 ? res.employee[0].is_conta_tracing : "N/A",
            res.cross_employee.length > 0
              ? res.cross_employee[0].name
              : "Guest",
            res.cross_employee.length > 0
              ? res.cross_employee[0].employee_id
              : "N/A",
            res.cross_employee.length > 0
              ? res.cross_employee[0].is_conta_tracing
              : "N/A",
            res.distance,
            res.source,
            format(new Date(res.cross_date), "do LLL hh:mm a")
          );
          index = index + 1;
        }
      });
    } else {
      var index = 1;
      this.state.breaches.map((res, i) => {
        if (res.source !== "mobile") {
          rows[i + 1] = new Array(
            res.employee.length > 0 ? res.employee[0].name : "Guest",
            res.employee.length > 0 ? res.employee[0].employee_id : "N/A",
            res.employee.length > 0 ? res.employee[0].is_conta_tracing : "N/A",
            res.cross_employee.length > 0
              ? res.cross_employee[0].name
              : "Guest",
            res.cross_employee.length > 0
              ? res.cross_employee[0].employee_id
              : "N/A",
            res.cross_employee.length > 0
              ? res.cross_employee[0].is_conta_tracing
              : "N/A",
            res.distance,
            res.source,
            format(new Date(res.cross_date), "do LLL hh:mm a")
          );
          index = index + 1;
        }
      });
    }

    console.log(rows);
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `breach_report.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  openImage = (url) => {
    this.setState({
      isOpenImage: true,
      selectedImage: url,
    });
  };
  closeImage = () => {
    this.setState({
      isOpenImage: false,
    });
  };

  render() {
    console.log(this.props.conta);
    const columns = [
      {
        name: "NAME",
        selector: (row) => {
          employee.name;
        },
        cell: (row) => (
          <div>{row.employee.length > 0 ? row.employee[0].name : "Guest"}</div>
        ),
      },
      {
        name: "ID",
        cell: (row) => (
          <div>
            {row.employee.length > 0 ? row.employee[0].employee_id : "N/A"}
          </div>
        ),
      },
      {
        name: "COVID-19",
        cell: (row) => (
          <div>
            {row.employee.length > 0
              ? row.employee[0].is_conta_tracing
                ? "Positive"
                : "Negetive"
              : "N/A"}
          </div>
        ),
      },
      {
        name: "BREACH WITH",
        cell: (row) => (
          <div>
            {row.cross_employee.length > 0
              ? row.cross_employee[0].name
              : "Guest"}
          </div>
        ),
      },
      {
        name: "ID",
        cell: (row) => (
          <div>
            {row.cross_employee.length > 0
              ? row.cross_employee[0].employee_id
              : "N/A"}
          </div>
        ),
      },
      {
        name: "COVID-19",
        cell: (row) => (
          <div>
            {row.cross_employee.length > 0
              ? row.cross_employee[0].is_conta_tracing
                ? "Positive"
                : "Negetive"
              : "N/A"}
          </div>
        ),
      },
      /* {
        name: "DISTANCE (+ /- 1.5 FT )",
        selector: "distance",
        cell: row => <div>{parseFloat(row.distance).toFixed(2)}</div>
      }, */
      {
        name: "SOURCE",
        selector: "source",
        cell: (row) => (
          <>
            {row.source === "mobile" ? (
              "mobile"
            ) : (
              <img
                style={{ cursor: "pointer" }}
                src={row.cross_feed != null ? row.cross_feed : ""}
                width={50}
                onClick={() => this.openImage(row.cross_feed)}
              />
            )}
          </>
        ),
      },
      {
        name: "DATE",
        selector: "cross_date",
        sortable: true,
        cell: (row) => (
          <div>
            {row.cross_date != null
              ? format(new Date(row.cross_date), "do LLL, hh:mm a")
              : ""}
          </div>
        ),
      },
    ];
    return (
      <div className={s.root}>
        <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>Contact History</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="mb-lg">Contact History</h1>
        <Row>
          <Col sm={12} md={12}>
            <Widget
              title={
                <div>
                  <div className="pull-right mt-n-xs mr-md">
                    <button
                      type="button"
                      className="btn btn-success btn-lg"
                      onClick={(e) => this.exportAttendanceToCSV()}
                    >
                      Export CSV
                    </button>
                  </div>
                  <div className="pull-right mt-n-xs mr-md">
                    <Input
                      type="select"
                      value={this.state.type}
                      onChange={(e) => this.onChangeType(`${e.target.value}`)}
                    >
                      <option value="mobile">Mobile</option>
                      <option value="computer">Computer Vision</option>
                    </Input>
                  </div>
                  <div className="pull-right mt-n-xs mr-md">
                    <Input
                      type="select"
                      name="camera"
                      id="camera"
                      onChange={(e) => this.onChange(`${e.target.value}`)}
                    >
                      <option value={1}>Today</option>
                      <option value={7}>7 days</option>
                      <option value={14}>14 days</option>
                    </Input>
                  </div>
                </div>
              }
            >
              <DataTable
                keyField="_id"
                columns={columns}
                data={this.state.breaches}
                pagination
                paginationPerPage={10}
                customStyles={customStyles}
              />
            </Widget>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenImage}
          onClosed={() => this.closeImage()}
        >
          <ModalHeader>Breach Image</ModalHeader>
          <ModalBody>
            <img src={this.state.selectedImage} width={470} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.closeImage()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.contatrack.isFetching,
    conta: state.contatrack.conta,
  };
}

export default connect(mapStateToProps)(Contatrack);
