import { combineReducers } from 'redux';
import auth from './auth';
import runtime from './runtime';
import navigation from './navigation';
import posts from './posts';
import feed from './feed';
import attendance from './attendance';
import contatrack from './contatrack';
import unsafe from './unsafe';
import ticket from './ticket';
import numberplate from './numberplate';

export default combineReducers({
  auth,
  runtime,
  navigation,
  posts,
  feed,
  attendance,
  contatrack,
  unsafe,
  ticket,
  numberplate
});
