
import { get, post, getApi, deleteApi, postAuth } from '../util/Service';

export const FETCH_WS_FEED = 'FETCH_WS_FEED';
export const FETCH_CAMERA_SUCCESS = 'FETCH_CAMERA_SUCCESS';
export const FETCH_ROI_SUCCESS = 'FETCH_ROI_SUCCESS';
export const DELETE_ROI_SUCCESS = 'DELETE_ROI_SUCCESS';
export const FETCH_ALGO_SUCCESS = 'FETCH_ALGO_SUCCESS';
export const UPDATE_CAMERA_SUCCESS = 'UPDATE_CAMERA_SUCCESS';

function fetchWSFeed(feed) {
  return {
    type: FETCH_WS_FEED,
    isFetching: false,
    feed
  };
}

function fetchCameraSuccess(cameras) {
  return {
    type: FETCH_CAMERA_SUCCESS,
    isFetching: false,
    cameras
  };
}

function fetchRoiSuccess(roi) {
  return {
    type: FETCH_ROI_SUCCESS,
    isFetching: false,
    roi
  };
}

function deteleRoiSuccess(deleteRoi) {
  return {
    type: DELETE_ROI_SUCCESS,
    isFetching: false,
    deleteRoi
  };
}

function fetchAlgoSuccess(algorithms) {
  return {
    type: FETCH_ALGO_SUCCESS,
    isFetching: false,
    algorithms
  };
}

function updateCameraSuccess(camera_id) {
  return {
    type: UPDATE_CAMERA_SUCCESS,
    isFetching: false,
    camera_id
  };
}

export function fetchFeed(camera_id) {
  if(camera_id != ''){
    let company_id = localStorage.getItem('pass_key');
    const feed_url = 'https://ai.hipla.io/company/'+company_id+'/camera/'+camera_id+'/live/raw';
    return dispatch => {
      dispatch(fetchWSFeed(feed_url));
    }
  }else {
    const feed_url = '';
    return dispatch => {
      dispatch(fetchWSFeed(feed_url));
    }
  }
  
}

export function fetchCameras() {
  return dispatch => {
    let cmpId = localStorage.getItem('pass_key');
    post('/cameras', {company_id: cmpId}).then(res => {
      console.log(res.data)
      dispatch(fetchCameraSuccess(res.data));
    });
  }
}


export function fetchRoi(camera_id) {
  return dispatch => {
    getApi(`/api/roi/${camera_id}`).then(res => {
      console.log(res.data)
      dispatch(fetchRoiSuccess(res.data));
    });
  }
}

export function deleteRoi(camera_id) {
  return dispatch => {
    deleteApi(`/api/roi/${camera_id}`).then(res => {
      dispatch(deteleRoiSuccess(res.data));
    });
  }
}

export function fetchAlgorithms() {
  return dispatch => {
    getApi('/api/algorithm').then(res => {
      //console.log(res.data.data)
      dispatch(fetchAlgoSuccess(res.data.data));
    });
  }
}

export function updateCamera(camera_id) {
  return dispatch => {
    dispatch(updateCameraSuccess(camera_id));
  }
}
