const customStyles = {
  table: {
    style: {
      color: "#000",
      backgroundColor: "transparent",
    },
  },
  header: {
    style: {
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
  headRow: {
    style: {
      backgroundColor: "transparent",
      borderBottomColor: "#eee",
      borderBottomStyle: "solid",
    },
  },
  headCells: {
    style: {
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: 15,
    },
  },
  cells: {
    style: {
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
  pagination: {
    style: {
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
};

export default customStyles;
