import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Breadcrumb, BreadcrumbItem, Input } from 'reactstrap';

import Widget from '../../components/Widget';

// Charts
import LineChart from './charts/LineChart';
import BarChart from './charts/BarChart';
import BarChartSingle from './charts/BarChartSingle';
import PercentAreaChart from './charts/PercentAreaChart';
import PieChart from './charts/PieChart';
import AreaChart from './charts/AreaChart';
import PieChartLabel from './charts/PieChartLabel';
import PieChartSingle from './charts/PieChartSingle';
import ReactSpeedometer from "react-d3-speedometer"
import { format } from 'date-fns';
import BreachTrend from './charts/BreachTrend';
import Infected from './charts/Infected';
import UnsafeChartSingle from './charts/UnsafeChartSingle';
import PeopleChartSingle from './charts/PeopleChartSingle';
import { fetchCameras } from "../../actions/feed";
import UnsafeBreach from './charts/UnsafeBreach';
import HardhatBreach from './charts/HardhatBreach';
import PeopleBreach from './charts/PeopleBreach';
import HardhatChartSingle from './charts/HardhatChartSingle';
import FacemaskChartSingle from './charts/FacemaskChartSingle';
import FacemaskBreach from './charts/FacemaskBreach';

const speed_data = [
  { car_speed: 29, date_time: format(new Date("2021-03-17T06:51:47.409268Z"), 'hh:MM a') },
  { car_speed: 51, date_time: format(new Date("2021-03-17T07:51:47.409268Z"), 'hh:MM a') },
  { car_speed: 42, date_time: format(new Date("2021-03-17T08:51:47.409268Z"), 'hh:MM a') },
  { car_speed: 35, date_time: format(new Date("2021-03-17T09:51:47.409268Z"), 'hh:MM a') },
  { car_speed: 32, date_time: format(new Date("2021-03-17T10:51:47.409268Z"), 'hh:MM a') },
  { car_speed: 53, date_time: format(new Date("2021-03-17T11:51:47.409268Z"), 'hh:MM a') },
  { car_speed: 56, date_time: format(new Date("2021-03-17T12:51:47.409268Z"), 'hh:MM a') },
];

const attendane_data = [
  { total: 117, date: "2021-03-15" },
  { total: 73, date: "2021-03-16" },
  { total: 132, date: "2021-03-17" },
  { total: 120, date: "2021-03-18" },
  { total: 38, date: "2021-03-19" },
];

const mystyle = {
  padding: 40,
  marginLeft: "20%"
}
const mL = {
  marginLeft: 30
}


class Charts extends PureComponent {

  state = {
    title: "Social Distance",
    module1: false,
    module2: false,
    module3: false,
    module4: false,
    module5: true,
    module6: false,
    module7: false,
    sidemenu: "1",
  };

  onChange = (value) => {
    switch (value) {
      case "1": {
        this.setState({
          title: "Occupancy Monitoring",
          module1: true,
          module2: false,
          module3: false,
          module4: false,
          module5: false,
          module6: false,
          module7: false,
        })
        break;
      }
      case "2": {
        this.setState({
          title: "Intruder Detection",
          module1: false,
          module2: true,
          module3: false,
          module4: false,
          module5: false,
          module6: false,
          module7: false,
        })
        break;
      }
      case "3": {
        this.setState({
          title: "Speed Detection",
          module1: false,
          module2: false,
          module3: true,
          module4: false,
          module5: false,
          module6: false,
          module7: false,
        })
        break;
      }
      case "4": {
        this.setState({
          title: "Attendance Monitoring",
          module1: false,
          module2: false,
          module3: false,
          module4: true,
          module5: false,
          module6: false,
          module7: false,
        })
        break;
      }
      case "5": {
        this.setState({

          title: "Social Distance",
          module1: false,
          module2: false,
          module3: false,
          module4: false,
          module5: true,
          module6: false,
          module7: false,
        })
        break;
      }
      case "6": {
        this.setState({
          title: "Hard-Hat",
          module1: false,
          module2: false,
          module3: false,
          module4: false,
          module5: false,
          module6: true,
          module7: false,
        })
        break;
      }
      case "7": {
        this.setState({
          title: "Face-Mask",
          module1: false,
          module2: false,
          module3: false,
          module4: false,
          module5: false,
          module6: false,
          module7: true,
        })
        break;
      }
      default: {
        this.setState({
          title: "Social Distance",
          module1: true,
          module2: false,
          module3: false,
          module4: false,
          module5: false,
          module6: false,
          module7: false,
        })
        break;
      }
    }
  }

  static defaultProps = {
    cameras: []
  }
  //another dropdown
  componentDidMount() {
    this.props.dispatch(fetchCameras());
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>Insights</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="page-title mb-lg"><span className="fw-semi-bold">{this.state.title}</span></h1>
        <Row className="mb-lg">
          <Col xs={6} md={3}>
          </Col>
          <Col xs={6} md={3}>
            <Input type="select" className="form-control input-sm" onChange={(e) => this.onChange(`${e.target.value}`)}>
              <option value="5">Social Distance Breach Insights</option>
              <option value="2">Intruder Detection Compliance</option>
              <option value="1" >Occupancy Monitor Insights</option>
              {/* <option value="3">Speed Detection</option>
              <option value="4">Attendance Monitoring</option> */}
              <option value="6">Hard-Hat Compliance</option>
              <option value="7">Face-Mask Compliance</option>
            </Input>
          </Col>
        </Row>
        <Row>
          {this.state.module1 && (
            <>
              <Col xs={12} md={8}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Occupancy Monitoring</span></h5>}>
                  {/* <LineChart data={data} /> */}
                  <PeopleChartSingle />
                </Widget>
              </Col>
              <Col xs={12} md={4} className="text-align-center">
                {/* <Widget
                  title={<h5><span className="fw-semi-bold">Occupancy %</span></h5>}>
                  <PieChart />
                </Widget> */}
                <PeopleBreach />
              </Col>
            </>
          )}
          {this.state.module2 && (
            <>
              <Col xs={12} md={8}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Number of breaches</span></h5>}>
                  <UnsafeChartSingle />
                </Widget>
              </Col>
              <Col xs={12} md={4} className="text-align-center">
                {/* <Widget
                  title={<h5><span className="fw-semi-bold">Occupancy %</span></h5>}>
                  <PieChart />
                </Widget> */}
                <UnsafeBreach/>
              </Col>
            </>
          )}
          {this.state.module3 && (
            <>
              <Col xs={12} md={8}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Speed Detection</span></h5>}>
                  <BarChart data={speed_data} />
                </Widget>
              </Col>
              <Col xs={12} md={4}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Average Speed</span></h5>}>
                  <ReactSpeedometer
                    maxValue={100}
                    value={45}
                    needleColor="red"
                    startColor="green"
                    segments={10}
                    endColor="blue"
                  />
                </Widget>
              </Col>
            </>
          )}
          {this.state.module4 && (
            <>
              <Col xs={12} md={8}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Weekly Attendance Report</span></h5>}>
                  <LineChart data={attendane_data} />
                </Widget>
              </Col>
              <Col xs={12} md={4}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Average Attendance</span></h5>}>
                  <PieChartLabel />
                </Widget>
              </Col>
            </>
          )}
          {this.state.module5 && (
            <>
              <Col xs={12} md={6} className="text-align-center">
                <Widget
                  title={<h5><span className="fw-semi-bold">Safety Indicator</span></h5>}>
                  <PieChartSingle />
                </Widget>
              </Col>
              <Col xs={12} md={6}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Breach history</span></h5>}>
                  <BarChartSingle />
                </Widget>
              </Col>
              <Col xs={12} md={7}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Breach trend</span></h5>}>
                  <BreachTrend />
                </Widget>
              </Col>
              <Col xs={12} md={5}>
                <Infected />
              </Col>
            </>
          )}
          {this.state.module6 && (
            <>
              <Col xs={12} md={8}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Hard-Hat</span></h5>}>
                  {/* <LineChart data={data} /> */}
                  <HardhatChartSingle />
                </Widget>
              </Col>
              <Col xs={12} md={4} className="text-align-center">

                <HardhatBreach />
              </Col>
            </>
          )}
          {this.state.module7 && (
            <>
              <Col xs={12} md={8}>
                <Widget
                  title={<h5><span className="fw-semi-bold">Face-Mask</span></h5>}>
                  {/* <LineChart data={data} /> */}
                  <FacemaskChartSingle />
                </Widget>
              </Col>
              <Col xs={12} md={4} className="text-align-center">
                <FacemaskBreach />
              </Col>
            </>
          )}

        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.feed.isFetching,
    cameras: state.feed.cameras
  };
}

export default connect(mapStateToProps)(Charts);