import appConfig from '../config';
import { postConta } from '../util/Service';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
}

export function receiveLogin(user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: user.id_token,
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

// Logs the user out
export function logoutUser() {
  return dispatch => {
    dispatch(requestLogout());
    localStorage.removeItem('id_token');
    document.cookie = 'id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    dispatch(receiveLogout());
  };
}

export function loginUser(creds) {

  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    //dispatch(requestLogin(creds));
    //if(process.env.NODE_ENV === "development") {
    /* const user = { id_token: appConfig.id_token }
    localStorage.setItem('id_token', user.id_token);
    dispatch(receiveLogin(user));
    return Promise.resolve(user); */

    const data = { email: creds.login, password: creds.password }
    postConta('/api/company/login', data)
      .then(u => {
        console.log(u.data.success)
        if (u.data.success) {
          const token = String(u.data.data.token);
          const user = { id_token: token }
          console.log(token)
          localStorage.setItem('id_token', token);
          localStorage.setItem('pass_key', u.data.data.company.unique_id);
          dispatch(receiveLogin(user));
          return Promise.resolve(user);
        }
        else {
          dispatch(loginError("Login failed"));
        }
      })
      .catch(err => {
        dispatch(loginError("Login failed"));
      })
  };
}
