import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;
const data = [
  { name: 'Seat Empty', value: 65 }, { name: 'Seat Occupancy', value: 35 }
];

class SimplePieChart extends PureComponent {
  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }

  render() {
    return (
      <ResponsiveContainer height={300} width='100%'>
        <PieChart  onMouseEnter={this.onPieEnter} height={297} width={420}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={130}
            dataKey="value"
            nameKey="name"
            fill="gray"
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            align="right"
            layout="horizontal"
            align="center"
            iconSize={12}
            iconType="circle"
            wrapperStyle={{ fontSize: "14px", paddingLeft: "10px" }}
          />
        </PieChart>
        {/* <PieChart onMouseEnter={this.onPieEnter}>
          <Pie
            data={data}
            cx='50%'
            cy={100}
            labelLine={false}
            label={this.renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {
              data.map((entry, index) => <Cell key={entry} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
        </PieChart> */}
      </ResponsiveContainer>
    );
  }
}

export default SimplePieChart;
