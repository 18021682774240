import {
  FETCH_UNSAFE_TICKET_REQUEST,
  FETCH_UNSAFE_TICKET_SUCCESS,
  FETCH_UNSAFE_TICKET_FAILURE,
  CHANGE_TICKET_SUCCESS,
  GET_TICKET_STATUS_SUCCESS,
  GET_TICKET_LOG_SUCCESS,
  GET_TICKET_DETAILS_SUCCESS,
  GET_TICKET_BREACH_SUCCESS,
} from '../actions/ticket';

export default function ticket(
  state = {
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_UNSAFE_TICKET_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_UNSAFE_TICKET_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        tickets: action.tickets,
      });
    case FETCH_UNSAFE_TICKET_FAILURE:
      return Object.assign({}, state, {
        isFetching: true,
        message: 'Something wrong happened. Please come back later',
      });
    case CHANGE_TICKET_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        ticket: action.ticket,
      });
    case GET_TICKET_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        status: action.status,
      });
    case GET_TICKET_LOG_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        logs: action.logs,
      });
    case GET_TICKET_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        ticket: action.ticket,
      });
    case GET_TICKET_BREACH_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        breaches: action.breaches,
      });
    default:
      return state;
  }
}
